import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'

import Footer from '../components/Footer'
import Header from '../components/Header'
import Logo from '../images/top_mainimage.png'

class RulePage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isArticleVisible: false,
            timeout: false,
            articleTimeout: false,
            article: '',
            loading: 'is-loading'
        }
        this.handleOpenArticle = this.handleOpenArticle.bind(this)
        this.handleCloseArticle = this.handleCloseArticle.bind(this)
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    componentDidMount() {
        this.timeoutId = setTimeout(() => {
            this.setState({ loading: '' });
        }, 100);
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleOpenArticle(article) {

        this.setState({
            isArticleVisible: !this.state.isArticleVisible,
            article
        })

        setTimeout(() => {
            this.setState({
                timeout: !this.state.timeout
            })
        }, 325)

        setTimeout(() => {
            this.setState({
                articleTimeout: !this.state.articleTimeout
            })
        }, 350)

    }

    handleCloseArticle() {

        this.setState({
            articleTimeout: !this.state.articleTimeout
        })

        setTimeout(() => {
            this.setState({
                timeout: !this.state.timeout
            })
        }, 325)

        setTimeout(() => {
            this.setState({
                isArticleVisible: !this.state.isArticleVisible,
                article: ''
            })
        }, 350)

    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.state.isArticleVisible) {
                this.handleCloseArticle();
            }
        }
    }




    render() {
        return (
            <Layout location={this.props.location} >
                <div>
                    <Header />
                    <div id="rule">
                        <div className="rulebox">
                            <div className="ruletitle">著作権について</div>
                            <div className="ruletext">応募作品の著作権は応募者に帰属します。ただし、応募者は主催者（VRAA運営委員会）が広報⽬的で応募作品の複製・加⼯・動画化・公開を、応募者への許可をとることなしに⾏うことを認めるものとします。</div>
                            </div>
                        <div className="rulebox">
                            <div className="ruletitle">禁止事項</div>
                            <div className="ruletext">応募作品はオリジナル作品に限ります。応募作品に第三者の著作による素材（美術・写真・フォントなど） を使⽤する場合、著作権、肖像権は、必ず応募者⾃⾝で著作者の許諾を得るものとします。第三者の権利を侵害する素材を使⽤した場合は、発覚した時点で、選考対象外となります。<br />また、仮に第三者から応募者または主催者に向けて権利侵害や損害賠償などの主張がなされたとしても、主催者は⼀切の責任を負いません。<br />⽇本国内法、および国際法に抵触するあらゆる⾏為を禁⽌します。第三者に物理的・精神的損害を与える⾏為、第三者の名誉を毀損する⾏為、第三者のいかなる権利を侵害する⾏為を禁⽌します。また、公序良俗に反するあらゆる⾏為を禁⽌します。</div>
                        </div>
                        <div className="rulebox">
                            <div className="ruletitle">個人情報の取り扱いについて</div>
                            <div className="ruletext">本アワードにてご提供いただいた個⼈情報は、審査、もしくはアワード運営に関わる連絡⽬的のみに利⽤します。業務上必要な範囲内で、業務委託先に開⽰、提供する場合を除き、本⼈の同意を得ることなく個⼈情報を第三者に開⽰・提供することはありません。</div>
                        </div>
                        <div className="rulebox">
                            <div className="ruletitle">その他注意事項</div>
                            <div className="ruletext">1.当アワードとVRChatとの間には直接の関係はありません。VRChatへの当イベントに関するお問い合わせはご遠慮ください。<br />
                                2.当アワードポータルワールド及び応募案ワールドでの迷惑行為・荒らし行為はおやめください。<br />
                                3.予期しない事態によりスケジュール通りの開催が困難になった場合は、別スケジュールにて開催いたします。<br />
                                4.極端にPCへの負荷が高いワールドに対しては、改善のお願いをする場合があります。また、改善が見られない場合、体験者の安全性を考慮してポータルワールドへの配置を取りやめる場合があります。応募前にVR/Desktopでの負荷対策を十分に行ってください。<br /></div>
                        </div>
                        </div>
                            <div id="wrapper-footer">
                                <Footer timeout={this.state.timeout} />
                            </div>
                    <div id="bg"></div>
                </div>
                <Helmet>
                    <html lang="jp" />
                    <title>VRAA02応募規約／プライバシーポリシー</title>
                    <meta name="description" content="Virtual Reality Architecture Award(VRAA)は、人類の生き方や楽しみ方をアップデートするVR空間＝ワールドを幅広く募集するデザインアワードです。今年のテーマは「Live/Frontier」新作・過去作問わず、人類の生き方や楽しみ方をアップデートするVRワールドを幅広く募集します！" />
                    <meta property="og:site_name" content="VRAA02" />
                    <meta property="og:image" content='https://vraa.jp/static/top_mainimage-fbe5e76929f9ae30bdaac989a322d96b.png' />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="VRAA02" />
                    <meta name="twitter:description" content="Virtual Reality Architecture Award(VRAA)は、人類の生き方や楽しみ方をアップデートするVR空間＝ワールドを幅広く募集するデザインアワードです。今年のテーマは「Live/Frontier」新作・過去作問わず、人類の生き方や楽しみ方をアップデートするVRワールドを幅広く募集します！" />
                    <meta name="twitter:image" content='https://vraa.jp/static/top_mainimage-fbe5e76929f9ae30bdaac989a322d96b.png' />
                </Helmet>
            </Layout>
        )
    }
}

export default RulePage
